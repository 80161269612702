import { put, call, takeLatest, take } from 'redux-saga/effects'
import services from '../../services'
import productServices from '../../services/products'
import {
  GET_APPLICANT,
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_ERROR,
  GET_APPLICANT_SUMMARY,
  GET_APPLICANT_SUMMARY_SUCCESS,
  GET_APPLICANT_SUMMARY_ERROR,
  GET_CURRICULUM,
  GET_CURRICULUM_SUCCESS,
  GET_CURRICULUM_ERROR,
  ADD_DATOS_PERSONALES_SUCCESS,
  ADD_DATOS_PERSONALES_ERROR,
  ADD_DATOS_PERSONALES,
  UPDATE_IMAGE_PROFILE,
  UPDATE_IMAGE_PROFILE_SUCCESS,
  UPDATE_IMAGE_PROFILE_ERROR,
  UpdateDatosContacto,
  AddDatosPersonales,
  UpdateImageProfile,
  UPDATE_DATOS_CONTACTO,
  UPDATE_DATOS_CONTACTO_SUCCESS,
  UPDATE_DATOS_CONTACTO_ERROR,
  UPDATE_DOCUMENTO,
  UpdateDocumento,
  UPDATE_DOCUMENTO_SUCCESS,
  UPDATE_DOCUMENTO_ERROR,
  UpdateDocumentoSuccess,
  UpdateDocumentoError,
  UpdateDatosPersonalesOnbording,
  UpdateDatosPersonalesOnbordingSuccess,
  UpdateDatosPersonalesOnbordingError,
  GET_VISUALIZACIONES_CV,
  GET_VISUALIZACIONES_CV_SUCCESS,
  GET_VISUALIZACIONES_CV_ERROR,
  GET_DATOS_PERSONALES,
  GET_DATOS_PERSONALES_SUCCESS,
  GET_DATOS_PERSONALES_ERROR,
  DELETE_IMAGE_PROFILE,
  DELETE_IMAGE_PROFILE_SUCCESS,
  DELETE_IMAGE_PROFILE_ERROR,
  UpdateCanalesDeContacto,
  UPDATE_CANALES_DE_CONTACTO_SUCCESS,
  UPDATE_CANALES_DE_CONTACTO_ERROR,
  UPDATE_CANALES_DE_CONTACTO,
} from '../types/applicant/applicant'
import {
  AddOrUpdateEstudio,
  ADD_OR_UPDATE_ESTUDIO,
  ADD_OR_UPDATE_ESTUDIO_SUCCESS,
  ADD_OR_UPDATE_ESTUDIO_ERROR,
  DELETE_ESTUDIO,
  DELETE_ESTUDIO_SUCCESS,
  DELETE_ESTUDIO_ERROR,
  DeleteEstudio,
  DeleteReferenciaEstudio,
  DELETE_REFERENCIA_ESTUDIO,
  DELETE_REFERENCIA_ESTUDIO_SUCCESS,
  DELETE_REFERENCIA_ESTUDIO_ERROR,
  UpdateReferenciaEstudio,
  UPDATE_REFERENCIA_ESTUDIO_SUCCESS,
  UPDATE_REFERENCIA_ESTUDIO_ERROR,
  UPDATE_REFERENCIA_ESTUDIO,
  AddEstudios,
  ADD_ESTUDIOS_ERROR,
  ADD_ESTUDIOS_SUCCESS,
  ADD_ESTUDIOS,
  SIN_ESTUDIOS_SUCCESS,
  SIN_ESTUDIOS_ERROR,
  SIN_ESTUDIOS,
  UpdateCertificadoEstudio,
  UPDATE_CERTIFICADO_ESTUDIO,
  UPDATE_CERTIFICADO_ESTUDIO_SUCCESS,
  UPDATE_CERTIFICADO_ESTUDIO_ERROR,
  DELETE_CERTIFICADO_ESTUDIO_SUCCESS,
  DELETE_CERTIFICADO_ESTUDIO_ERROR,
  DeleteCertificadoEstudio,
  DELETE_CERTIFICADO_ESTUDIO,
  SinEstudios,
} from '../types/applicant/curriculum/estudios'
import {
  DeleteIdioma,
  DELETE_IDIOMA,
  DELETE_IDIOMA_SUCCESS,
  DELETE_IDIOMA_ERROR,
  UpdateIdioma,
  UPDATE_IDIOMA_ERROR,
  UPDATE_IDIOMA_SUCCESS,
  UPDATE_IDIOMA,
  AddIdiomas,
  ADD_IDIOMAS_ERROR,
  ADD_IDIOMAS_SUCCESS,
  ADD_IDIOMAS,
} from '../types/applicant/curriculum/idiomas'
import {
  DELETE_DISCAPACIDAD,
  DELETE_DISCAPACIDAD_SUCCESS,
  DELETE_DISCAPACIDAD_ERROR,
  AddDiscapacidad,
  ADD_DISCAPACIDAD,
  ADD_DISCAPACIDAD_SUCCESS,
  ADD_DISCAPACIDAD_ERROR,
  GET_DISCAPACIDAD,
  GET_DISCAPACIDAD_SUCCESS,
  GET_DISCAPACIDAD_ERROR,
  GetDiscapacidad,
} from '../types/applicant/curriculum/discapacidad'
import {
  GetCvAdjunto,
  GET_CV_ADJUNTO,
  GET_CV_ADJUNTO_SUCCESS,
  GET_CV_ADJUNTO_ERROR,
  DELETE_CV_ADJUNTO,
  DELETE_CV_ADJUNTO_SUCCESS,
  DELETE_CV_ADJUNTO_ERROR,
  AddCvAdjunto,
  ADD_CV_ADJUNTO,
  ADD_CV_ADJUNTO_SUCCESS,
  ADD_CV_ADJUNTO_ERROR,
} from '../types/applicant/curriculum/cv-adjunto'
import {
  GetVideoPresentacion,
  GET_VIDEO_PRESENTACION,
  GET_VIDEO_PRESENTACION_SUCCESS,
  GET_VIDEO_PRESENTACION_ERROR,
  AddVideoPresentacion,
  ADD_VIDEO_PRESENTACION,
  ADD_VIDEO_PRESENTACION_SUCCESS,
  ADD_VIDEO_PRESENTACION_ERROR,
  DELETE_VIDEO_PRESENTACION,
  DELETE_VIDEO_PRESENTACION_SUCCESS,
  DELETE_VIDEO_PRESENTACION_ERROR,
  GetVideoPresentacionPreview,
  GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS,
  GET_VIDEO_PRESENTACION_PREVIEW_ERROR,
  GET_VIDEO_PRESENTACION_PREVIEW,
} from '../types/applicant/curriculum/video-presentacion'
import {
  GET_PREFERENCIA_SALARIAL,
  GET_PREFERENCIA_SALARIAL_SUCCESS,
  GET_PREFERENCIA_SALARIAL_ERROR,
  UpdatePreferenciaSalarial,
  UPDATE_PREFERENCIA_SALARIAL_SUCCESS,
  UPDATE_PREFERENCIA_SALARIAL_ERROR,
  UPDATE_PREFERENCIA_SALARIAL,
  DELETE_PREFERENCIA_SALARIAL_SUCCESS,
  DELETE_PREFERENCIA_SALARIAL_ERROR,
  DELETE_PREFERENCIA_SALARIAL,
} from '../types/applicant/curriculum/preferencia-salarial'
import {
  UpdateObjetivoLaboral,
  UPDATE_OBJETIVO_LABORAL_SUCCESS,
  UPDATE_OBJETIVO_LABORAL_ERROR,
  UPDATE_OBJETIVO_LABORAL,
  DELETE_OBJETIVO_LABORAL_SUCCESS,
  DELETE_OBJETIVO_LABORAL_ERROR,
  DELETE_OBJETIVO_LABORAL,
} from '../types/applicant/curriculum/objetivo-laboral'
import {
  UPDATE_SKILLS_SUCCESS,
  UPDATE_SKILLS_ERROR,
  UPDATE_SKILLS,
  UpdateSkills,
  DELETE_SKILLS_SUCCESS,
  DELETE_SKILLS_ERROR,
  DeleteSkills,
  DELETE_SKILLS,
  ReplaceSkills,
  REPLACE_SKILLS,
  REPLACE_SKILLS_SUCCESS,
  REPLACE_SKILLS_ERROR,
} from '../types/applicant/curriculum/skills'
import {
  UPDATE_PDA_SUCCESS,
  UPDATE_PDA_ERROR,
  UPDATE_PDA,
  UpdatePda,
  GET_LINK_REPORTE_SUCCESS,
  GET_LINK_REPORTE_ERROR,
  GET_LINK_REPORTE,
} from '../types/applicant/curriculum/pda'
import {
  DELETE_EXPERIENCIA,
  DELETE_EXPERIENCIA_SUCCESS,
  DELETE_EXPERIENCIA_ERROR,
  DeleteExperiencia,
  DeleteReferenciaExperiencia,
  DELETE_REFERENCIA_EXPERIENCIA,
  DELETE_REFERENCIA_EXPERIENCIA_SUCCESS,
  DELETE_REFERENCIA_EXPERIENCIA_ERROR,
  UpdateExperiencia,
  UPDATE_EXPERIENCIA_SUCCESS,
  UPDATE_EXPERIENCIA_ERROR,
  UPDATE_EXPERIENCIA,
  UpdateReferenciaLaboral,
  UPDATE_REFERENCIA_LABORAL_SUCCESS,
  UPDATE_REFERENCIA_LABORAL_ERROR,
  UPDATE_REFERENCIA_LABORAL,
  PRIMER_EMPLEO_SUCCESS,
  PRIMER_EMPLEO_ERROR,
  PRIMER_EMPLEO,
  PrimerEmpleo,
  UpdateExperiencias,
  UPDATE_EXPERIENCIAS_SUCCESS,
  UPDATE_EXPERIENCIAS_ERROR,
  UPDATE_EXPERIENCIAS,
} from '../types/applicant/curriculum/experiencia-laboral'
import { SIGN_IN_GOOGLE_SUCCESS, SIGN_IN_LINKEDIN_SUCCESS, SIGN_IN_SUCCESS } from '../types/sign-in'
import { SIGN_UP_SUCCESS } from '../types/sign-up'
import { CONFIRM_CANDIDATE_ACCOUNT_SUCCESS } from '../types/applicant/confirm-candidate'
import { TIENE_CV, TIENE_CV_ERROR, TIENE_CV_SUCCESS } from '../types/applicant/curriculum/tiene-cv'
import {
  GetProductAvailabilityError,
  GetProductAvailabilitySuccess,
  GET_PRODUCT_AVAILABILITY_ERROR,
  GET_PRODUCT_AVAILABILITY_SUCCESS,
  ProductAvailability,
} from '../types/products'
import {
  UPDATE_DATOS_PERSONALES_ONBORDING,
  UPDATE_DATOS_PERSONALES_ONBORDING_ERROR,
  UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS,
} from '../types/applicant/curriculum/datos-personales-onbording'
import {
  ACTUALIZAR_FECHA_CV,
  ACTUALIZAR_FECHA_CV_ERROR,
  ACTUALIZAR_FECHA_CV_SUCCESS,
} from '../types/applicant/curriculum/fecha-cv'
import { GET_CERTIFICADO_ADJUNTO, GetCertificadoAdjunto } from '../types/applicant/curriculum/certificado-adjunto'

function* getApplicant() {
  try {
    const result = yield call(services.applicant.GetApplicant)
    yield put({ type: GET_APPLICANT_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_APPLICANT_ERROR, error })
  }
}

function* getApplicantSummary() {
  try {
    const result = yield call(services.applicant.GetApplicantSummary)
    yield put({ type: GET_APPLICANT_SUMMARY_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_APPLICANT_SUMMARY_ERROR, error })
  }
}

function* getDatosPersonales() {
  try {
    const result = yield call(services.applicant.GetDatosPersonales)
    yield put({ type: GET_DATOS_PERSONALES_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_DATOS_PERSONALES_ERROR, error })
  }
}

function* getCurriculum() {
  try {
    const result = yield call(services.applicant.GetCurriculum)
    yield put({ type: GET_CURRICULUM_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CURRICULUM_ERROR, error })
  }
}

function* addDatosPersonales({ payload }: AddDatosPersonales) {
  try {
    yield call(services.applicant.UpdateDatosPersonles, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_DATOS_PERSONALES_SUCCESS, payload })
    } else {
      yield put({ type: ADD_DATOS_PERSONALES_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_DATOS_PERSONALES_ERROR, error })
  }
}

function* updateDatosPersonalesOnbording({ payload }: UpdateDatosPersonalesOnbording) {
  try {
    yield call(services.applicant.UpdateDatosPersonlesOnbording, payload)
    yield put<UpdateDatosPersonalesOnbordingSuccess>({ type: UPDATE_DATOS_PERSONALES_ONBORDING_SUCCESS, payload: null })
  } catch (error) {
    yield put<UpdateDatosPersonalesOnbordingError>({ type: UPDATE_DATOS_PERSONALES_ONBORDING_ERROR, payload: null })
  }
}

function* updateImageProfile({ payload }: UpdateImageProfile) {
  try {
    yield call(services.applicant.UpdateImageProfile, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_IMAGE_PROFILE_SUCCESS, payload })
    } else {
      yield put({ type: UPDATE_IMAGE_PROFILE_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_IMAGE_PROFILE_ERROR, error })
  }
}

function* deleteImageProfile() {
  try {
    yield call(services.applicant.DeleteImageProfile)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_IMAGE_PROFILE_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_IMAGE_PROFILE_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_IMAGE_PROFILE_ERROR, error })
  }
}

function* updateDatosContacto({ payload }: UpdateDatosContacto) {
  try {
    yield call(services.applicant.UpdateDatosContacto, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_DATOS_CONTACTO_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_DATOS_CONTACTO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_DATOS_CONTACTO_ERROR, error })
  }
}

function* updateCanalesDeContacto({ payload }: UpdateCanalesDeContacto) {
  try {
    yield call(services.applicant.UpdateCanalesDeContanto, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_CANALES_DE_CONTACTO_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_CANALES_DE_CONTACTO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_CANALES_DE_CONTACTO_ERROR, error })
  }
}

function* deleteDiscapacidad() {
  try {
    yield call(services.applicant.DeleteDiscapacidad)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_DISCAPACIDAD_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_DISCAPACIDAD_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_DISCAPACIDAD_ERROR, error })
  }
}

function* addDiscapacidad({ payload }: AddDiscapacidad) {
  try {
    yield call(services.applicant.AddDiscapacidad, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_DISCAPACIDAD_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_DISCAPACIDAD_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_DISCAPACIDAD_ERROR, error })
  }
}

function* getDiscapacidad({ payload }: GetDiscapacidad) {
  try {
    const result = yield call(services.applicant.GetDiscapacidad, payload)
    yield put({ type: GET_DISCAPACIDAD_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_DISCAPACIDAD_ERROR, error })
  }
}

function* addCvAdjunto({ payload }: AddCvAdjunto) {
  try {
    yield call(services.applicant.AddCvAdjunto, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_CV_ADJUNTO_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_CV_ADJUNTO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_CV_ADJUNTO_ERROR, error })
  }
}

function* getCertificadoAdjunto({ payload }: GetCertificadoAdjunto) {
  try {
    const result = yield call(services.applicant.GetCertificadoAdjunto, payload)
    yield put({ type: GET_CV_ADJUNTO_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CV_ADJUNTO_ERROR, error })
  }
}

function* getCvAdjunto({ payload }: GetCvAdjunto) {
  try {
    const result = yield call(services.applicant.GetCvAdjunto, payload)
    yield put({ type: GET_CV_ADJUNTO_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_CV_ADJUNTO_ERROR, error })
  }
}

function* deleteCvAdjunto() {
  try {
    yield call(services.applicant.DeleteCvAdjunto)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_CV_ADJUNTO_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_CV_ADJUNTO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_CV_ADJUNTO_ERROR, error })
  }
}

function* getVideoPresentacion({ payload }: GetVideoPresentacion) {
  try {
    const result = yield call(services.applicant.GetVideoPresentacion, payload)
    yield put({ type: GET_VIDEO_PRESENTACION_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_VIDEO_PRESENTACION_ERROR, error })
  }
}

function* getVideoPresentacionPreview({ payload }: GetVideoPresentacionPreview) {
  try {
    const result = yield call(services.applicant.GetVideoPresentacion, payload)
    yield put({ type: GET_VIDEO_PRESENTACION_PREVIEW_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_VIDEO_PRESENTACION_PREVIEW_ERROR, error })
  }
}

function* addVideoPresentacion({ payload }: AddVideoPresentacion) {
  try {
    yield call(services.applicant.AddVideoCv, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_VIDEO_PRESENTACION_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_VIDEO_PRESENTACION_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_VIDEO_PRESENTACION_ERROR, error })
  }
}

function* deleteVideoPresentacion() {
  try {
    yield call(services.applicant.DeleteVideoPresentacion)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_VIDEO_PRESENTACION_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_VIDEO_PRESENTACION_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_VIDEO_PRESENTACION_ERROR, error })
  }
}

function* sinEstudios({ payload }: SinEstudios) {
  try {
    yield call(services.applicant.sinEstudios, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: SIN_ESTUDIOS_SUCCESS, payload: null })
    } else {
      yield put({ type: SIN_ESTUDIOS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: SIN_ESTUDIOS_ERROR, error })
  }
}

function* addOrUpdateEstudio({ payload }: AddOrUpdateEstudio) {
  try {
    yield call(services.applicant.AddOrUpdateEstudio, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_OR_UPDATE_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_OR_UPDATE_ESTUDIO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_OR_UPDATE_ESTUDIO_ERROR, error })
  }
}

function* addEstudios({ payload }: AddEstudios) {
  try {
    yield call(services.applicant.AddEstudios, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_ESTUDIOS_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_ESTUDIOS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_ESTUDIOS_ERROR, error })
  }
}

function* deleteEstudio({ payload: estudioId }: DeleteEstudio) {
  try {
    yield call(services.applicant.DeleteEstudio, estudioId)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_ESTUDIO_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_ESTUDIO_ERROR, payload: null })
  }
}

function* deleteReferenciaEstudio({ payload: referenciaId }: DeleteReferenciaEstudio) {
  try {
    yield call(services.applicant.DeleteReferenciaEstudio, referenciaId)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_REFERENCIA_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_REFERENCIA_ESTUDIO_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_REFERENCIA_ESTUDIO_ERROR, payload: null })
  }
}

function* deleteIdioma({ payload: idIdioma }: DeleteIdioma) {
  try {
    yield call(services.applicant.DeleteIdioma, idIdioma)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_IDIOMA_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_IDIOMA_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_IDIOMA_ERROR, payload: null })
  }
}

function* updateReferenciaEstudio({ payload }: UpdateReferenciaEstudio) {
  try {
    yield call(services.applicant.UpsertReferenciaEstudio, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_REFERENCIA_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_REFERENCIA_ESTUDIO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_REFERENCIA_ESTUDIO_ERROR, error })
  }
}

function* updateCertificadoEstudio({ payload }: UpdateCertificadoEstudio) {
  try {
    yield call(services.applicant.AddCertificadoEstudio, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_CERTIFICADO_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_CERTIFICADO_ESTUDIO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_CERTIFICADO_ESTUDIO_ERROR, error })
  }
}

function* deleteCertificadoEstudio({ payload: estudioId }: DeleteCertificadoEstudio) {
  try {
    yield call(services.applicant.DeleteCertificadoEstudio, estudioId)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_CERTIFICADO_ESTUDIO_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_CERTIFICADO_ESTUDIO_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_CERTIFICADO_ESTUDIO_ERROR, payload: null })
  }
}

function* updateReferenciaLaboral({ payload }: UpdateReferenciaLaboral) {
  try {
    yield call(services.applicant.UpsertReferenciaLaboral, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_REFERENCIA_LABORAL_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_REFERENCIA_LABORAL_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_REFERENCIA_LABORAL_ERROR, error })
  }
}

function* updateIdioma({ payload }: UpdateIdioma) {
  try {
    yield call(services.applicant.UpsertIdioma, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_IDIOMA_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_IDIOMA_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_IDIOMA_ERROR, error })
  }
}

function* addIdiomas({ payload }: AddIdiomas) {
  try {
    yield call(services.applicant.AddIdiomas, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: ADD_IDIOMAS_SUCCESS, payload: null })
    } else {
      yield put({ type: ADD_IDIOMAS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: ADD_IDIOMAS_ERROR, error })
  }
}

function* getPreferenciaSalarial() {
  try {
    const result = yield call(services.applicant.GetPreferenciaSalarial)
    yield put({ type: GET_PREFERENCIA_SALARIAL_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_PREFERENCIA_SALARIAL_ERROR, error })
  }
}

function* updatePreferenciaSalarial({ payload }: UpdatePreferenciaSalarial) {
  try {
    yield call(services.applicant.UpdatePreferenciaSalarial, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_PREFERENCIA_SALARIAL_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_PREFERENCIA_SALARIAL_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_PREFERENCIA_SALARIAL_ERROR, error })
  }
}

function* deletePreferenciaSalarial() {
  try {
    yield call(services.applicant.DeletePreferenciaSalarial)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_PREFERENCIA_SALARIAL_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_PREFERENCIA_SALARIAL_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_PREFERENCIA_SALARIAL_ERROR, error })
  }
}

function* updateObjetivoLaboral({ payload }: UpdateObjetivoLaboral) {
  try {
    yield call(services.applicant.UpdtaeObjetivoLaboral, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_OBJETIVO_LABORAL_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_OBJETIVO_LABORAL_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_OBJETIVO_LABORAL_ERROR, error })
  }
}

function* deleteObjetivoLaboral() {
  try {
    yield call(services.applicant.DeleteObjetivoLaboral)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_OBJETIVO_LABORAL_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_OBJETIVO_LABORAL_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: DELETE_OBJETIVO_LABORAL_ERROR, error })
  }
}

function* updateSkills({ payload }: UpdateSkills) {
  try {
    yield call(services.applicant.UpdateSkills, { datos: payload, replaceSkills: false })
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_SKILLS_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_SKILLS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_SKILLS_ERROR, error })
  }
}

function* replaceSkills({ payload }: ReplaceSkills) {
  try {
    // reciclamos el servicio UpdateSkills para que se pueda utilizar para reemplazar
    yield call(services.applicant.UpdateSkills, { datos: payload, replaceSkills: true })
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: REPLACE_SKILLS_SUCCESS, payload: null })
    } else {
      yield put({ type: REPLACE_SKILLS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: REPLACE_SKILLS_ERROR, error })
  }
}

function* deleteSkill({ payload }: DeleteSkills) {
  try {
    yield call(services.applicant.DeleteSkill, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_SKILLS_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_SKILLS_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_SKILLS_ERROR, payload: null })
  }
}

function* deleteExperiencia({ payload: experienciaId }: DeleteExperiencia) {
  try {
    yield call(services.applicant.DeleteExperiencia, experienciaId)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_EXPERIENCIA_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_EXPERIENCIA_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_EXPERIENCIA_ERROR, payload: null })
  }
}

function* deleteReferenciaExperiencia({ payload: referenciaId }: DeleteReferenciaExperiencia) {
  try {
    yield call(services.applicant.DeleteReferenciaExperiencia, referenciaId)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: DELETE_REFERENCIA_EXPERIENCIA_SUCCESS, payload: null })
    } else {
      yield put({ type: DELETE_REFERENCIA_EXPERIENCIA_ERROR, payload: null })
    }
  } catch (error) {
    yield put({ type: DELETE_REFERENCIA_EXPERIENCIA_ERROR, payload: null })
  }
}

function* updatePda({ payload }: UpdatePda) {
  try {
    yield call(services.applicant.UpdatePda, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_PDA_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_PDA_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_PDA_ERROR, error })
  }
}

function* getLinkReportePda() {
  try {
    const link = yield call(services.applicant.GetLinkReportePda)
    yield put({ type: GET_LINK_REPORTE_SUCCESS, payload: link })
  } catch (error) {
    yield put({ type: GET_LINK_REPORTE_ERROR, payload: null })
  }
}

function* updateExperiencias({ payload }: UpdateExperiencias) {
  try {
    yield call(services.applicant.AddExperiencias, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_EXPERIENCIAS_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_EXPERIENCIAS_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_EXPERIENCIAS_ERROR, error })
  }
}

function* updateExperiencia({ payload }: UpdateExperiencia) {
  try {
    yield call(services.applicant.UpsertExperiencia, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: UPDATE_EXPERIENCIA_SUCCESS, payload: null })
    } else {
      yield put({ type: UPDATE_EXPERIENCIA_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: UPDATE_EXPERIENCIA_ERROR, error })
  }
}

function* primerEmpleo({ payload }: PrimerEmpleo) {
  try {
    yield call(services.applicant.markFirstJobSeeker, payload)
    yield put({ type: GET_CURRICULUM, payload: null })
    const action = yield take([GET_CURRICULUM_SUCCESS, GET_CURRICULUM_ERROR])
    if (action.type === GET_CURRICULUM_SUCCESS) {
      yield put({ type: PRIMER_EMPLEO_SUCCESS })
    } else {
      yield put({ type: PRIMER_EMPLEO_ERROR, error: null })
    }
  } catch (error) {
    yield put({ type: PRIMER_EMPLEO_ERROR, error })
  }
}

function* tieneCV() {
  try {
    const resp = yield call(services.applicant.tieneCV)
    yield put({ type: TIENE_CV_SUCCESS, payload: resp.existe })
  } catch (error) {
    yield put({ type: TIENE_CV_ERROR, payload: null })
  }
}

function* updateDocumento({ payload }: UpdateDocumento) {
  const { productSku } = payload
  delete payload.productSku
  try {
    yield call(services.applicant.UpdateDocumento, payload)
    if (productSku) {
      try {
        const result: ProductAvailability[] = yield call(productServices.getProductAvailability, productSku)
        yield put<GetProductAvailabilitySuccess>({ type: GET_PRODUCT_AVAILABILITY_SUCCESS, payload: result })
        yield put<UpdateDocumentoSuccess>({ type: UPDATE_DOCUMENTO_SUCCESS, payload })
      } catch (error) {
        yield put<GetProductAvailabilityError>({ type: GET_PRODUCT_AVAILABILITY_ERROR, payload: true })
      }
    } else {
      yield put<UpdateDocumentoSuccess>({ type: UPDATE_DOCUMENTO_SUCCESS, payload })
    }
  } catch (error) {
    yield put<UpdateDocumentoError>({ type: UPDATE_DOCUMENTO_ERROR, payload: true })
  }
}

function* actualizarFechaCV() {
  try {
    yield call(services.applicant.actualizarFechaCV)
    yield put({ type: ACTUALIZAR_FECHA_CV_SUCCESS, payload: null })
  } catch (error) {
    yield put({ type: ACTUALIZAR_FECHA_CV_ERROR, payload: null })
  }
}

function* getVisualizacionesCV() {
  try {
    const result = yield call(services.applicant.getVisualizacionesCurriculum)
    yield put({ type: GET_VISUALIZACIONES_CV_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_VISUALIZACIONES_CV_ERROR, payload: null })
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest(GET_APPLICANT, getApplicant)
  yield takeLatest(GET_APPLICANT_SUMMARY, getApplicantSummary)
  yield takeLatest(SIGN_IN_SUCCESS, getApplicantSummary)
  yield takeLatest([SIGN_IN_GOOGLE_SUCCESS, SIGN_IN_LINKEDIN_SUCCESS], getApplicantSummary)
  yield takeLatest(SIGN_UP_SUCCESS, getApplicantSummary)
  yield takeLatest(GET_CURRICULUM, getCurriculum)
  yield takeLatest(ADD_DATOS_PERSONALES, addDatosPersonales)
  yield takeLatest<UpdateDatosPersonalesOnbording>(UPDATE_DATOS_PERSONALES_ONBORDING, updateDatosPersonalesOnbording)
  yield takeLatest(UPDATE_IMAGE_PROFILE, updateImageProfile)
  yield takeLatest(DELETE_IMAGE_PROFILE, deleteImageProfile)
  yield takeLatest(UPDATE_DATOS_CONTACTO, updateDatosContacto)
  yield takeLatest(UPDATE_CANALES_DE_CONTACTO, updateCanalesDeContacto)
  yield takeLatest(SIN_ESTUDIOS, sinEstudios)
  yield takeLatest(ADD_OR_UPDATE_ESTUDIO, addOrUpdateEstudio)
  yield takeLatest(ADD_ESTUDIOS, addEstudios)
  yield takeLatest(DELETE_ESTUDIO, deleteEstudio)
  yield takeLatest(DELETE_REFERENCIA_ESTUDIO, deleteReferenciaEstudio)
  yield takeLatest(DELETE_IDIOMA, deleteIdioma)
  yield takeLatest(UPDATE_REFERENCIA_ESTUDIO, updateReferenciaEstudio)
  yield takeLatest(UPDATE_CERTIFICADO_ESTUDIO, updateCertificadoEstudio)
  yield takeLatest(DELETE_CERTIFICADO_ESTUDIO, deleteCertificadoEstudio)
  yield takeLatest(UPDATE_REFERENCIA_LABORAL, updateReferenciaLaboral)
  yield takeLatest(UPDATE_IDIOMA, updateIdioma)
  yield takeLatest(ADD_IDIOMAS, addIdiomas)
  yield takeLatest(DELETE_DISCAPACIDAD, deleteDiscapacidad)
  yield takeLatest(ADD_DISCAPACIDAD, addDiscapacidad)
  yield takeLatest(GET_DISCAPACIDAD, getDiscapacidad)
  yield takeLatest(GET_CV_ADJUNTO, getCvAdjunto)
  yield takeLatest(GET_CERTIFICADO_ADJUNTO, getCertificadoAdjunto)
  yield takeLatest(DELETE_CV_ADJUNTO, deleteCvAdjunto)
  yield takeLatest(ADD_CV_ADJUNTO, addCvAdjunto)
  yield takeLatest(GET_VIDEO_PRESENTACION, getVideoPresentacion)
  yield takeLatest(GET_VIDEO_PRESENTACION_PREVIEW, getVideoPresentacionPreview)
  yield takeLatest(ADD_VIDEO_PRESENTACION, addVideoPresentacion)
  yield takeLatest(DELETE_VIDEO_PRESENTACION, deleteVideoPresentacion)
  yield takeLatest(GET_PREFERENCIA_SALARIAL, getPreferenciaSalarial)
  yield takeLatest(UPDATE_PREFERENCIA_SALARIAL, updatePreferenciaSalarial)
  yield takeLatest(DELETE_PREFERENCIA_SALARIAL, deletePreferenciaSalarial)
  yield takeLatest(UPDATE_OBJETIVO_LABORAL, updateObjetivoLaboral)
  yield takeLatest(DELETE_OBJETIVO_LABORAL, deleteObjetivoLaboral)
  yield takeLatest(UPDATE_SKILLS, updateSkills)
  yield takeLatest(REPLACE_SKILLS, replaceSkills)
  yield takeLatest(DELETE_SKILLS, deleteSkill)
  yield takeLatest(DELETE_EXPERIENCIA, deleteExperiencia)
  yield takeLatest(DELETE_REFERENCIA_EXPERIENCIA, deleteReferenciaExperiencia)
  yield takeLatest(UPDATE_PDA, updatePda)
  yield takeLatest(GET_LINK_REPORTE, getLinkReportePda)
  yield takeLatest(UPDATE_EXPERIENCIA, updateExperiencia)
  yield takeLatest(UPDATE_EXPERIENCIAS, updateExperiencias)
  yield takeLatest(CONFIRM_CANDIDATE_ACCOUNT_SUCCESS, getApplicantSummary)
  yield takeLatest(PRIMER_EMPLEO, primerEmpleo)
  yield takeLatest(TIENE_CV, tieneCV)
  yield takeLatest(UPDATE_DOCUMENTO, updateDocumento)
  yield takeLatest(ACTUALIZAR_FECHA_CV, actualizarFechaCV)
  yield takeLatest(GET_VISUALIZACIONES_CV, getVisualizacionesCV)
  yield takeLatest(GET_DATOS_PERSONALES, getDatosPersonales)
}
